import React from "react";
import error from "../assets/images/error-page-icon.png";
import style from "./Error.module.scss";

const ErrorFallback = ({ err }) => {
  return (
    <div className={style.main}>
      <div className="text-center">
        <img className="mt-3" src={error} alt="icons" />
        <h1 className="mt-2" style={{color:'red'}} >Oops! Error!</h1>
        <h3>Sorry, Something went Wrong!</h3>
        <h6>{err.message}</h6>
        <button className="mt-4" onClick={(e) => window.location.reload()}>
          REFRESH
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback;
