export const moduleName = {
  profile: "profile",
  academics: "academics",
  dashboard: "dashboard",
  group: "group",
  addGroup: "add-group",
  editGroup: "edit-group",
  deleteGroup: "delete-group",
  updateGroupStatus: "update-group-status",
  feedback: "feedback-list",
  feedbackAction: "Feedback-Action-Open",
  feedbackSubmission: "Feedback-Action-submission",
  FeedbackViewSubmitted: "feedback-view-submitted-feedback",
  program: "program",
  newsfeedCreatePostAnouncement: "newsfeed-create-post-announcement",
  newsfeedEditPostAnouncement: "newsfeed-edit-post-announcement",
  newsfeedDeletePostAnouncement: "newsfeed-delete-post-announcement",
  programAdd: "program-add",
  programEdit: "program-edit",
  programDelete: "program-delete",
  programDragContent: "program-drag-content",
  liveclass: "live-class",
  liveclassAdd: "live-class-add",
  liveclassEdit: "edit-live-class",
  liveclassDelete: "delete-live-class",
  joinLiveclass: "join-live-class",
  liveclassInteractive: "live-class-meeting-interactive",
  liveclassFlexible: "live-class-meeting-flexible",
  liveClassAuthority: "authoritylink-live-class",
  liveClassStudent: "studentlink-live-class",
  liveClassUpload: "live-class-add-recording-button",
  liveClassParticipantsAll: "live-class-participants-all",
  liveClassCopyLink: "live-class-copy-link",
  assignmentAdd: "assignment-add",
  assigmentPublished: "assignment-tab-published",
  assignmentDraft: "assignment-tab-draft",
  assignmentExpired: "assignment-tab-expired",
  assignmentMarkAsDraft: "assignment-action-markAsDraft",
  assignmentQuestionDropdown: "assignment-action-question",
  assignmentSubmissionDropDown: "assignment-action-submission",
  assignmentEdit: "assignment-action-edit",
  assignmentDelete: "assignment-action-delete",
  assignmentSubmit: "assignment-action-submit",
  assignmentResult: "assignment-action-result",
  assignmentReview: "assignment-action-review",
  forumAddTopic: "forum-add-topic",
  forumDownload: "forum-download",
  forumEditTopic: "Forum-edit-topic",
  forumDeleteTopic: "Forum-delete-topic",
  forumAddComment: "forum-comment-add",
  forumEditComment: "forum-comment-edit",
  forumDeleteComment: "forum-comment-delete",
  forumMyTopic: "forum-my-topic",
  forumMyAnswer: "forum-my-answers",
  forumTopUsers: "forum-top-users",
  attendenceCalenderFacultyView: "attendance-faculty-calender-view",
  attendenceLeaveAdd: "attendance-leave-add",
  attendenceLeaveCancel: "attendance-leave-cancel",
  assignmentQuestionAdd: "assignment-CreateQuestions",
  assignmentQuestionEdit: "assignment-updateQuestions",
  assignmentQuestionDelete: "assignment-deleteQuestions",
  planner: "program-planner",
  gradeFacultyView: "grade-student-list",
  gradeStudentView: "grade-view-student",
  gradeStudentDownload: "grade-download-student",
  gradeImportAndExport: "grade-import-export",
  exam: "exam",
  examAdd: "add-exam",
  examEdit: "edit-exam",
  examDelete: "delete-exam",
  examList: "exam-list",
  test: "test",
  testSchedule: "test-schedule",
  statusExam: "change-status-exam",
  admission: "admission",
  programPlanner: "program-planner",
  areaOfInterestView: "area-of-interest",
  areaOfInterestAdd: "add-area-of-interest",
  areaOfInterestEdit: "edit-area-of-interest",
  areaOfInterestDelete: "delete-area-of-interest",
  courseView: "course",
  courseAdd: "course-add",
  courseEdit: "course-edit",
  courseDelete: "course-delete",
  mainProgramView: "main-program",
  mainProgramAdd: "add-main-program",
  mainProgramEdit: "edit-main-program",
  mainProgramDelete: "delete-main-program",
  subProgramAdd: "add-program",
  subProgramEdit: "edit-program",
  subProgramDelete: "delete-program",
  programOfferView: "program-offer",
  programOfferAdd: "add-program-offer",
  programOfferEdit: "edit-program-offer",
  offeredCourses: "offeredCourses",
  programOfferDelete: "delete-program-offer",
  programOfferPreview: "preview",
  officeView: "office",
  officeAdd: "office-add",
  officeEdit: "office-edit",
  officeDelete: "office-delete",
  classroomView: "classroom",
  classroomAdd: "classroom-add",
  classroomEdit: "classroom-edit",
  classroomDelete: "classroom-delete",
  partnerView: "technical-partner",
  partnerAdd: "technical-partner-add",
  partnerEdit: "technical-partner-edit",
  partnerDelete: "technical-partner-delete",
  quizList: "list-quiz",
  quizAdd: "add-quiz",
  quizEdit: "edit-quiz",
  quizDelete: "delete-quiz",
  quizStatus: "change-status-quiz",
  userManagementTab: "user-management-tab-out",
  roleManagementTab: "roles-permission-tab-out",
  initedUserManagementList: "invited-user-management-list",
  userManagementList: "user-management-list",
  rolePermDefaultTab: "roles-permission-tab-default",
  rolePermCustomTab: "roles-permission-tab-custom",
  userManagementEdit: "edit-user-management",
  userManagementDelete: "delete-user-management",
  userManagementStatus: "status-change-user-management",
  initedUserManagementResend: "resend-invite-user-management",
  initedUserManagementEdit: "invite-edit-user-management",
  initedUserManagementDelete: "invite-delete-user-management",
  studentInformationSystemAdmin: "student-information-system-admin",
  sisAdminStatusChange: "sis-admin-status-change",
  sisAdminAdd: "sis-admin-add",
  sisAdminEdit: "sis-admin-edit",
  sisAdminDelete: "sis-admin-delete",
  sisAdminExport: "sis-admin-export",
  sisAdminImport: "sis-admin-import",
  timeTableList: "time-table",
  timeTableEdit: "time-table-edit",
  timeTableStatus: "time-table-status-change",
  electiveAdminView: "all-Elective-Registered-Course",
  electiveStudentView: "elective-Registered-Course",
  queryManager: "registration-query-manager-admin",
  applicationManager: "registration-application-manager-admin",
  applicationViewOnly: "application-view-only",
  cmsGroup: "create-cms-group",
  cmsGroupEdit: "edit-cms-group",
  cmsGroupDelete: "delete-cms-group",
  createCms: "create-cms",
  createView: "list-cms-field",
  createEdit: "edit-cms",
  createDelete: "delete-cms",
  createCmsFields: "create-cms-fields",
  editCmsFields: "edit-cms-field",
  deleteCmsFields: "delete-cms-field",
  mediaAdd: "media-add",
  mediaDelete: "media-delete",
  hrmsLeaveApproval: "hrms-leave-approval",
  hrmsLeaveApprovalList: "hrms-leave-approval-list",
  hrmsLeaveSettings: "hrms-leave-settings",
  hrmsLeaveSettingList: "hrms-leave-settings-list",
  hrmsLeaveSettingAdd: "hrms-leave-settings-add",
  hrmsLeaveSettingEdit: "hrms-leave-settings-edit",
  hrmsLeaveSettingDelete: "hrms-leave-settings-delete",
  hrmsJobOpenings: "hrms-job-openings",
  hrmsJobOpeningsList: "hrms-job-openings-list",
  hrmsJobOpeningsAdd: "hrms-job-openings-add",
  hrmsJobOpeningsEdit: "hrms-job-openings-edit",
  hrmsJobOpeningsDelete: "hrms-job-openings-delete",
  hrmsJobOpeningsDetails: "hrms-job-openings-details",
  hrmsTalentPool: "hrms-job-openings-talent-pool",
  hrmsTalentPoolList: "hrms-job-openings-talent-pool-list",
  hrmsHolyday: "hrms-holyday",
  hrmsHolydayList: "hrms-holyday-list",
  hrmsHolydayAdd: "hrms-holyday-add",
  hrmsHolydayEdit: "hrms-holyday-edit",
  hrmsHolydayDelete: "hrms-holyday-delete",
  hrmsEmployeeExport: "hrms-employee-export",
  hrmsEmployeeAdd: "hrms-employee-add",
  hrmsEmployeeEdit: "hrms-employee-edit",
  hrmsEmployeeDelete: "hrms-employee-delete",
  hrmsEmployeeProfileUpdate: "hrms-employee-profile-update",
  hrmsEmployeeLeaveAdd: "hrms-employee-leave-add",
  hrmsEmployeeLeaveEdit: "hrms-employee-leave-edit",
  hrmsEmployeeLeaveDelete: "hrms-employee-leave-delete",
  hrmsResourcesList: "hrms-resources-list",
  hrmsResourcesAdd: "hrms-resources-add",
  hrmsResourcesDelete: "hrms-resources-delete",
  hrmsResourcesShare: "hrms-resources-share",
  hrmsResourcesPin: "hrms-resources-pin",
  hrmsResourcesShareRemove: "hrms-resources-access-delete",
  allElectiveRegistration: "all-elective-registration",
  allElectiveRegistrationList: "all-elective-registration-list",
  allElectiveRegistrationExport: "all-elective-registration-export",
  allElectiveRegistrationImport: "all-elective-registration-import",
  allElectiveRegistrationView: "all-elective-registration-view",
  allElectiveRegistrationOpen: "all-elective-registration-open",
  allElectiveRegistrationClose: "all-elective-registration-close",
  allElectiveRegistrationMerge: "all-elective-registration-merge",
  allElectiveRegistrationStudentList: "all-elective-registration-student-list",
  allElectiveRegistrationStudentExport:
    "all-elective-registration-student-export",
  allElectiveRegistrationStudentImport:
    "all-elective-registration-student-import",
  allElectiveRegistrationStudentAdd: "all-elective-registration-student-add",
  allElectiveRegistrationStudentAccept:
    "all-elective-registration-student-accept",
  allElectiveRegistrationStudentReject:
    "all-elective-registration-student-reject",
  allElectiveRegistrationCourseMergeList:
    "all-elective-registration-course-merge-list",
  allElectiveRegistrationCourseMerge: "all-elective-registration-course-merge",
  admissionManagerList: "admissionManager-list",
  applicationManagerList: "applicationManager-list",
  applicationManagerStatus: "applicationManager-status",
  applicationManagerDetail: "applicationManager-detail",
  queryManagerList: "queryManager-list",
  queryManagerStatus: "queryManager-status",
  formGenerationList: "formGeneration-list",
  formGenerationAdd: "formGeneration-add",
  formGenerationEdit: "formGeneration-edit",
  formGenerationStatus: "formGeneration-status",
  formGenerationDelete: "formGeneration-delete",
  formGenerationForm: "formGeneration-form",
  leadManagerList: "leadManager-list",
  paymentManagerList: "paymentManager-list",
  paymentManagerAccounts: "paymentManager-accounts",
  interviewManager: "interviewManager",
  interviewManagerList: "interviewManager-list",
  interviewManagerBoardView: "interviewManager-boardView",
  interviewManagerListView: "interviewManager-listView",
  interviewManagerApplicationExport: "interviewManager-application-export",
  interviewManagerScheduleInterview: "schedule-interview",
  interviewManagerMarkJoined: "mark-joined",
  interviewManagerDocumentVerify: "document-verify",
  interviewManagerMakeOffer: "make-offer",
  interviewManagerChangeStage: "change-stage",
  selection: "selection",
  registration: "registration",
  registrationMyForm: "registration-myForm",
  registrationMyQuery: "registration-myQuery",
  registrationMyPayment: "registration-myPayment",
  mainProgram: "main-program",
  programOffer: "program-offer",
  hrmsEmployeeView: "hrmsEmployee",
  cms: "cms",
  media: "media",
  cmsGroupView: "cmsGroup",
  leaveManagementAdmin: "leave-management-admin",
  alumniEventMenu: "alumni-event-menu",
  alumniEventRegister: "alumni-event-register",
  alumniMaster: "alumni-master",
  alumniNominationAwards: "alumni-nomination-awards",
  alumniJob: "alumni-job",
  alumniExecutive: "alumni-executive",
  merchant: "merchant",
  alumniDirectory: "alumni-directory",
  alumniNewsFeed: "alumni-news-feed",
  alumniEventRegBtn: "alumni-event-register-btn",
  profilePayment: "profile-payment",
  profileLeave: "profile-leave",
  profileResource: "profile-resource",
  profileHoliday: "profile-holiday",
  profileAttendance: "profile-attendance",
  profileNotice: "profile-notice",
  profileNoticeAction: "profile-notice-action",
  profileBank: "profile-bankDetails",
  profileChangePassword: "profile-change-password",
  profileWebsiteData: "profile-website-data",
  profileIdCard: "profile-idCard",
  viewChatRoom: "viewChatRoom",
  certificateGenerate: "certificate-generate",
  certificateTemplate: "certificate-template",
  alumniAwards: "alumni-awards",
  alumniGallery: "alumni-gallery",
  paymentGatewayList: "paymentGateway-list",
  paymentGatewayAdd: "paymentGateway-add",
  paymentGatewayEdit: "paymentGateway-edit",
  paymentGatewayDelete: "paymentGateway-delete",
  zoomHostList: "zoomHost-list",
  zoomHost: "zoomHost",
  zoomHostAdd: "zoomHost-add",
  zoomHostEdit: "zoomHost-edit",
  zoomHostDelete: "zoomHost-delete",
  reports: "reports",
  caseRepository: "case-repository",
  caseTransactions: "case-transactions",
  caseCustomer: "case-customer",
  attendanceManagement: "attendance-management",
  messManagement: "messManagement",
  ticketManagement: "ticketManagement",
  StudentAttendance: "student-attendance",
  ticketViewAll: "ticketViewAll",
  learning: "learning",
  paymentManagerAdmission: "payment-manager-admission",
  reportsAddMember: "reports-add-member",
  deleteReports: "reports-delete",
  createReports: "reports-create",
  hrmsLeaveRule: "hrms-leave-rules",
  hrmsLeaveRuleList: "hrms-leave-rules-list",
  hrmsLeaveRuleAdd: "hrms-leave-rules-add",
  hrmsLeaveRuleEdit: "hrms-leave-rules-edit",
  hrmsLeaveRuleDelete: "hrms-leave-rules-delete",
  shiftSetting: "shift-setting",
  shiftSettingList: "shift-setting-list",
  shiftSettingAdd: "shift-setting-add",
  shiftSettingEdit: "shift-setting-edit",
  shiftSettingDelete: "shift-setting-delete",
  dynamicForm: "dynamic-form",
  xatRegistration: "xat-registration",
  xatCreation: "xat-creation",
  ticketDeleteConversation: "ticket-delete-conversation",
  applicationManagerManualAdd: "applicationManager-manual-add",
  idCardGeneration: "idCard",
  noticeBoard: "notice-board",
  addNoticeBoard: "add-notice-board",
  editNoticeBoard: "edit-notice-board",
  deleteNoticeBoard: "delete-notice-board",
  resourceAdd: "resource-add",
  resourceShare: "resource-share",
  resourceRemove: "remove-resource",
  liveEvents: "live-events",
  addLiveEvent: "add-live-event",
  updateLiveEvent: "update-live-event",
  deleteLiveEvent: "delete-live-event",
  gradeComponentAdd: "grade-component-add-admin",
  verifyDocument: "application-verify-document",
  requestApproval: "request-approval",
  addRequestApproval: "add-request-approval",
  editRequestApproval: "edit-request-approval",
  deleteRequestApproval: "delete-request-approval",
  interviewManagerBookSlots: "interviewManager-book-slots",
  interviewManagerCreateSlots: "interviewManager-create-slots",
  fis: "fis",
  fisIntellectual: "fis-intellectual",
  fisClassification: "fis-classification",
  fisContribution: "fis-contribution",
  importInterviewApplicant: "import-applicant-interview",
  bulkEmail: "bulk-email",
  createBulkEmail: "bulk-email-create-template",
  updateBulkEmail: "bulk-email-update-template",
  deleteBulkEmai: "bulk-email-delete-template",
  sendbulkemail: "bulk-email-sendEmail",
  termDropdown: "termDropdown",
  parcelList: "parcel-list",
  parcelCreate: "parcel-create",
  parcelStudent: "parcel-student",
  bulkImportPaymentAdmission: "payment-manager-bulk-import-payment-admission",
  campusComponent: "campusComponent",
  campusComponentCreate: "campusComponent-create",
  campusComponentUpdate: "campusComponent-update",
  campusComponentDelete: "campusComponent-delete",
  dashboardCreate: "dashboard-create",
  dashboardUpdate: "dashboard-update",
  dashboardDelete: "dashboard-delete",
  mailList: "mail-list",
  createMailList: "mail-list-create",
  updateMailList: "mail-list-update",
  deleteMailList: "mail-list-delete",
  approvalSettings: "approval-settings",
  addApprovalSettings: "add-approval-settings",
  editApprovalSettings: "edit-approval-settings",
  deleteApprovalSettings: "delete-approval-settings",
  paymentManagerAccountsAdd: "paymentManager-accounts-add",
  search: "search",
  healthCare: "health-care",
  dynamicCreateForm: "dynamic-form-create",
  dynamicUpdateForm: "dynamic-form-update",
  dynamicFormDelete: "dynamic-form-delete",
  dynamicFormView: "dynamic-form-view",
  dynamicFormMembers: "dynamic-form-members",
  dynamicFormNested: "dynamic-form-nested",
  paymentManagerAccountAdmission: "paymentManager-accounts-admission",
  honorarium: "honorarium",
  addHonorarium: "add-honorarium",
  editHonorarium: "edit-honorarium",
  deleteHonorarium: "delete-honorarium",
  honorariumRequest: "honorarium-request",
  addHonorariumRequest: "add-honorarium-request",
  editHonorariumRequest: "edit-honorarium-request",
  deleteHonorariumRequest: "delete-honorarium-request",
  honorariumReport: "honorarium-report",
  admissionManagerListApplicant: "admissionManager-list-applicant",
  admissionManagerListStudent: "admissionManager-list-student",
  library: "library",
  libraryAdd: "library-add",
  libraryEdit: "library-edit",
  libraryDelete: "library-delete",
  mailBox: "mail-box",
  importFiles: "import-files",
  mySchedule: "my-schedule",
  reExam: "re-exam",
  addReExam: "add-re-exam",
  editReExam: "edit-re-exam",
  studentStatusChange: "student-status-change",
  grievanceViewAll: "grievanceViewAll",
  interviewManagerFacutyView: "faculty-view",
  interviewAssessmentTab: "assessment-tab",
  interviewAddAssessment: "add-assessment",
  studentPaymentPage: "student-payment-page",
  notifications: "notifications",
  offlinePayments: "paymentManager-offline-requests",
  interviewExportAll: "interview-export-all",
  dynamicFormTemplate: "dynamic-form-template",
  gradeTagp: "grade-tagp",
  capstoneProject: "capstone",
  addCmsContent: "add-cms-content",
  deleteCmsContent: "delete-cms-content",
  editCmsContent: "edit-cms-content",
  addCMSMember: "add-cms-member",
};
