import { createSlice } from "@reduxjs/toolkit";
import { setToken } from "../utils/utils";
import {
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { moduleName } from "../constants/moduleName";
import { firebaseAuth } from "../firebase";
import { toast } from "react-toastify";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: false,
    isAuthenticated: false,
    userData: null,
    profileData: null,
    selectedCourse: null,
    currentCourses: null,
    userDetails: null,
    uid: null,
    permissions: [],
    navigate: { previous: [], current: false },
    forgotPassword: false,
    moduleName: moduleName,
    mainProgram: null,
    registerUser: null,
    timeSys: null,
    profilePic: "",
    signature: "",
    zoomLink: null,
    applicationDetails: "",
    fcmToken: null,
    programDetail: null,
    chartId: null,
    userType: null,
    isMulti: null,
    selectedTerm: null,
    selectedStage: null,
    selectedPaymentStatus: null,
  },
  reducers: {
    loginUser: (state, action) => {
      setToken(action.payload);
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload.token,
        uid: action.payload.uid,
        loading: false,
      };
    },
    logoutUser: (state, action) => {
      if (action.payload?.cache) action.payload.clearStore();
      setToken();
      return {
        isAuthenticated: false,
        userData: null,
        uid: null,
        userDetails: null,
        currentCourses: null,
        permissions: {},
        profileData: null,
        selectedCourse: null,
        moduleName: moduleName,
        mainProgram: null,
        profilePic: "",
        signature: "",
        selectedTerm: null,
        selectedStage: null,
        selectedPaymentStatus: null,
      };
    },
    setUserData: (state, action) => {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
    setPermissions: (state, action) => {
      return {
        ...state,
        permissions: action.payload || [],
      };
    },
    setProfileData: (state, action) => {
      return {
        ...state,
        profileData: action.payload,
      };
    },
    setCurrentCourses: (state, action) => {
      return {
        ...state,
        currentCourses: action.payload,
      };
    },
    navigatePage: (state, action) => {
      return {
        ...state,
        navigate: action.payload,
      };
    },
    navPage: (state, action) => {
      try {
        let nav = { ...state.navigate };
        if (nav?.previous) {
          nav.previous = [...nav.previous];
        } else {
          nav.previous = [];
        }
        nav.current = false;
        nav.previous.push(action.payload);
        return {
          ...state,
          navigate: nav,
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    },
    setProfilePic: (state, action) => {
      try {
        return {
          ...state,
          profilePic: action.payload || "",
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    },
    setSignature: (state, action) => {
      try {
        return {
          ...state,
          signature: action.payload || "",
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    },
    clearNavigatePage: (state, action) => {
      return {
        ...state,
        navigate: { previous: [], current: false },
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setError: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
    setForgotPassword: (state, action) => {
      return {
        ...state,
        loading: true,
        forgotPassword: false,
      };
    },
    setForgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        forgotPassword: true,
      };
    },
    setForgotPasswordError: (state, action) => {
      return {
        ...state,
        loading: false,
        forgotPassword: action.payload,
      };
    },
    setSelectedCourse: (state, action) => {
      return {
        ...state,
        selectedCourse: action.payload,
      };
    },
    setSelectedTerm: (state, action) => {
      return {
        ...state,
        selectedTerm: action.payload,
      };
    },
    setSelectedStage: (state, action) => {
      return {
        ...state,
        selectedStage: action.payload,
      };
    },
    setSelectedPaymentStatus: (state, action) => {
      return {
        ...state,
        selectedPaymentStatus: action.payload,
      };
    },
    setMainProgram: (state, action) => {
      return {
        ...state,
        mainProgram: action.payload,
      };
    },
    setTimeSys: (state, action) => {
      return {
        ...state,
        timeSys: action.payload,
      };
    },
    registerUser: (state, action) => {
      return {
        ...state,
        loading: true,
        registerUser: false,
      };
    },
    registerUserSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        registerUser: action.payload,
      };
    },
    registerUserError: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    setZoomLink: (state, action) => {
      return {
        ...state,
        zoomLink: action.payload,
      };
    },
    setApplicationDetails: (state, action) => {
      return {
        ...state,
        applicationDetails: action.payload,
      };
    },
    setFcmToken: (state, action) => {
      return {
        ...state,
        fcmToken: action.payload,
      };
    },
    setProgramDetail: (state, action) => {
      return {
        ...state,
        programDetail: action.payload,
      };
    },
    setChartId: (state, action) => {
      return {
        ...state,
        chartId: action.payload,
      };
    },
    setUserType: (state, action) => {
      return {
        ...state,
        userType: action.payload,
      };
    },
    setIsMulti: (state, action) => {
      return {
        ...state,
        isMulti: action.payload,
      };
    },
  },
});

export const {
  loginUser,
  logoutUser,
  setUserData,
  setPermissions,
  setProfileData,
  navigatePage,
  navPage,
  clearNavigatePage,
  setLoading,
  setError,
  setForgotPassword,
  setForgotPasswordSuccess,
  setForgotPasswordError,
  setSelectedCourse,
  setCurrentCourses,
  setMainProgram,
  setTimeSys,
  registerUser,
  registerUserSuccess,
  registerUserError,
  setProfilePic,
  setSignature,
  setZoomLink,
  setApplicationDetails,
  setFcmToken,
  setProgramDetail,
  setChartId,
  setUserType,
  setIsMulti,
  setSelectedTerm,
  setSelectedStage,
  setSelectedPaymentStatus,
} = authSlice.actions;

export const forgotPasswordInitiate = (dispatch, email) => {
  dispatch(setForgotPassword());
  return function () {
    sendPasswordResetEmail(firebaseAuth, email)
      .then((data) => {
        dispatch(setForgotPasswordSuccess(data));
        alert("Please check your email...");
      })
      .catch((error) => {
        dispatch(setForgotPasswordError(error));
        console.error(error);
        toast.error(error.message);
      });
  };
};
export const userRegister = (dispatch, email, password) => {
  dispatch(registerUser());
  return function () {
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((data) => {
        dispatch(registerUserSuccess(data));
      })
      .catch((error) => {
        dispatch(registerUserError(error));
      });
  };
};

export default authSlice.reducer;
