import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import authReducer from './auth'
import cacheReducer from './cache'

const store = configureStore({
  reducer: {
    cache : cacheReducer,
    auth: authReducer
  },
  middleware: [thunk],
})

export default store
