import React, { Suspense, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import logo from "./assets/images/login-logo.png";
import googlePlay from "./assets/images/googlePlay.png";
import appStore from "./assets/images/appStore.png";

import { directionalLink, errorLink } from "./apollo";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import "./App.scss";
import { FullLoader } from "./components/loader";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logoutUser, setFcmToken } from "./redux/auth";
import { getToken } from "./utils/utils";
import { toast, ToastContainer } from "react-toastify";
import { setApolloClient } from "./redux/cache";
import PrivateRoute from "./hoc/PrivateRoute";
import { messaging } from "./firebase";
import { getToken as getFCMToken } from "firebase/messaging";
// import { addNotification } from "./helpers/SendNotification";
// import Zoom from "./components/Zoom";

const Register = React.lazy(() => import("./views/auth/Register/index"));

const Zoom = React.lazy(() => import("./components/Zoom"));

const EmailBuilder = React.lazy(() =>
  import("./components/EmailBuilder/EmailBuilder")
);

const ViewApp = React.lazy(() => import("./views/app"));

const ViewUser = React.lazy(() => import("./views/auth"));

const AlumniRegister = React.lazy(() =>
  import("./views/auth/alumniRegister/index")
);

const AlumniPaymentPage = React.lazy(() =>
  import("./views/app/alumni/alumniExecutiveInvite/paymentPage")
);

const ViewError = React.lazy(() => import("./views/Error"));

const ViewResource = React.lazy(() =>
  import("./components/resourceApp/ResourceWebPreview")
);

function App() {
  const dispatch = useDispatch();

  const mobilePath = "https://admissions.xlri.ac.in";
  const alumniPath = "https://alumni.xlri.ac.in";
  // const alumniPath = "https://shyam-82.twl-workspace.techwarelab.com";


  const apolloClientRef = useSelector((state) => state.cache.apollo);
  const { token, uid } = getToken();

  const authData = useSelector((state) => state.auth.userData);
  const authId = useSelector((state) => state.auth.uid);
  const isAuthed = useSelector((state) => state.auth.isAuthenticated);

  const onForbidden = (message = "Session Expired!") => {
    toast.warn(message);
    dispatch(logoutUser(apolloClientRef));
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //generate token
      const token = await getFCMToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPIDKEY,
      });
      dispatch(setFcmToken(token));
    } else if (permission === "denied") {
      toast.error("You have denied notification permission");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  const styles = {
    mobileMessage: {
      wrapper: {
        zIndex: 1,
        position: "fixed",
        left: 0,
        top: 0,
        textAlign: "center",
        padding: "40px",
        color: "#1b4e9b",
        fontSize: "20px",
        fontWeight: 700,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fffff",
      },
    },
    mobileLogo: {
      margin: "0 auto",
      marginTop: "10px",
      width: "100px",
      display: "block",
    },
  };

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            newsfeeds: {
              // shorthand
              merge: true,
            },
            getAllStudents: {
              merge(existing, incoming) {
                return incoming;
              },
            },
            ListForumWithCursor: {
              merge: true,
            },
            ListResourceWithCursor: {
              merge: true,
            },
            ListTypeUsersCursor: {
              merge: true,
            },
          },
        },
      },
    }),
    link: ApolloLink.from([
      errorLink(onForbidden),
      directionalLink(authData, authId),
    ]),
  });

  useEffect(() => {
    dispatch(setApolloClient(apolloClient));
    if (token) dispatch(loginUser({ token, uid }));
    else dispatch(logoutUser(apolloClientRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // if (!REACT_APP_GRAPHQL_PG) return <p>Down!!</p>;

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  return (
    <ApolloProvider client={apolloClient}>
      {window.location.origin === mobilePath ? (
        <div>
          <ToastContainer />
          <Suspense fallback={<FullLoader />}>
            <Routes>
              <Route path="/" element={<Navigate to="/app" replace="true" />} />
              <Route path="/app/*" element={<ViewApp isAuthed={isAuthed} />} />
              <Route
                path="/login/*"
                element={<ViewUser isAuthed={isAuthed} />}
              />
              <Route path="/error" element={<ViewError />} />
              <Route path="/register/:id/:token" element={<Register />} />
              <Route path="/resource-view/:id" element={<ViewResource />} />
              <Route path="/live-class" element={<Zoom />} />
              <Route path="/join-events" element={<Zoom />} />
              <Route path="/email-builder" element={<EmailBuilder />} />
              <Route path="/*" element={<Navigate to="/error" />} />
            </Routes>
          </Suspense>
        </div>
      ) : window.location.origin === alumniPath ? (
        <div>
          <ToastContainer />
          <Suspense fallback={<FullLoader />}>
            <Routes>
              <Route path="/" element={<Navigate to="/app" replace="true" />} />
              <Route path="/app/*" element={<ViewApp isAuthed={isAuthed} />} />
              <Route
                path="/login/*"
                element={
                  // windowSize[0] <= 767 && alumniPath ? (
                  //   <Navigate to="/alumni-register" replace="true" />
                  // ) : (
                  <ViewUser isAuthed={isAuthed} />
                  // )
                }
              />
              <Route
                path="/alumni-register"
                element={<AlumniRegister isAuthed={isAuthed} />}
              />
              <Route path="/alumni-payment" element={<AlumniPaymentPage />} />
              <Route path="/error" element={<ViewError />} />
              <Route path="/register/:id/:token" element={<Register />} />
              <Route path="/*" element={<Navigate to="/error" />} />
            </Routes>
          </Suspense>
        </div>
      ) : (
        <>
          <div className="desktopView">
            <ToastContainer />
            <Suspense fallback={<FullLoader />}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/app" replace="true" />}
                />
                <Route
                  path="/app/*"
                  element={<ViewApp isAuthed={isAuthed} />}
                />
                <Route
                  path="/login/*"
                  element={<ViewUser isAuthed={isAuthed} />}
                />
                <Route
                  path="/xat-associate-register"
                  element={<AlumniRegister isAuthed={isAuthed} />}
                />
                <Route path="/email-builder" element={<EmailBuilder />} />
                <Route path="/error" element={<ViewError />} />
                <Route path="/register/:id/:token" element={<Register />} />
                <Route path="/resource-view/:id" element={<ViewResource />} />
                <Route path="/live-class"  element={<Zoom />} />
                <Route path="/join-events" element={<Zoom />} />
                <Route path="/*" element={<Navigate to="/error" />} />
              </Routes>
            </Suspense>
          </div>
          <div className="mobileView">
            <div>
              <img style={styles.mobileLogo} src={logo} alt="logo" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={styles.mobileMessage.wrapper}
              >
                <div className="downloadLink">
                  <a href="https://play.google.com/store/apps/details?id=app.xlri.mobile">
                    <img src={googlePlay} alt="pic" />
                  </a>
                  <a href="https://apps.apple.com/app/xlri/id1643712293">
                    <img src={appStore} alt="pic" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ApolloProvider>
  );
}

export default App;
